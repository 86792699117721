<template>
  <div>
    <div class="table-container">
      <el-table
          class="table"
          height="100%"
          v-loading="tableLoading"
          :data="tableData">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="货架状态：">
                <span>{{ props.row.shelfStatus }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column
            label="设备id"
            prop="deviceId"
            align="center"
            header-align="center"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="设备imei"
            align="center"
            header-align="center"
            prop="deviceSn"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="设备类型"
            align="center"
            header-align="center"
        >
          <template slot-scope="{row}">
            {{ row.deviceType === 1 ? '有屏租赁柜' : row.deviceType === 2 ? '无屏租赁柜' : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            label="接收时间"
            align="center"
            header-align="center"
            prop="createTime"
            width="180"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="设备状态"
            align="center"
            header-align="center"
        >
          <template slot-scope="{row}">
            {{ row.status === 0 ? '异常' : row.status === 1 ? '正常' : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            label="apk版本号"
            align="center"
            header-align="center"
            prop="apkVer"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="apkToken"
            align="center"
            header-align="center"
            prop="apkToken"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="网络类型"
            align="center"
            header-align="center"
        >
          <template slot-scope="{row}">
            {{ row.netType === 1 ? 'LAN' : row.netType === 2 ? 'WIFI' : row.netType === 3 ? 'EDGE' : row.netType === 4 ? '3/4/5G' : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            label="信号质量"
            align="center"
            header-align="center"
            prop="netQuality"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="设备时间"
            align="center"
            header-align="center"
            prop="deviceTime"
            width="180"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            label="货架数"
            align="center"
            header-align="center"
            prop="shelfNumbers"
            :formatter="commonFormatter"
        >
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
        class="top_10 right right_10"
        :current-page="pageNum"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import tableUse from "@/mixins/tableUse";
export default {
  name: "HeartRecord",
  mixins:[tableUse],
  computed:{
    deviceId(){
      return this.$route.query.id
    }
  },
  data() {
    return {
      tableData: [
      ],
    }
  },
  methods:{
    getTableData(){
      this.tableLoading = true;
      let formData = {
        pageNum:this.pageNum,
        pageSize:this.pageSize,
        id:this.deviceId
      }
      this.$axios.post("/sys-api/device-api/heartbeatRecord",formData,(res) => {
        this.tableLoading = false;
        if(res.code === '100'){
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      })
    }
  },
  mounted() {
    this.getTableData();
  }
}
</script>

<style scoped lang="scss">
.table-container{
  height: calc(100% - 50px - 20px);
  padding-top: 20px;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
  padding-left: 48px;
}
.demo-table-expand ::v-deep.el-form-item__label {
  width: 90px ;
}
</style>
